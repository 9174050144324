<template>
  <div>
    <loader v-if="loading" />
    <template v-else>
      <div class="d-flex justify-content-end mb-3 margin-guide-not-visible">
        <erase-icon-button
          v-if="removeButtonVisible"
          class="mr-2"
          @click="onDelete"
        />
      </div>

      <template v-if="!isStartupCostsEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <distribution-of-startup-costs />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <startup-costs-over-time />
        </ib-card>
        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <startup-costs-table
            :table-rows-to-expand="tableRowsToExpand"
            :table-adjustment="tableAdjustment"
          />
        </ib-card>
      </template>
      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="startupCostsExamples"
      :visible="guideVisible || guideVisibleFirstTime"
      @close="guideVisible = false; guideVisibleFirstTime = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="startup-cost"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Startup Cost -->
    <dialog-add-startup-cost
      v-if="canEdit"
      :dialog-add-startup-cost-visible="dialogAddNewVisible"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @close-dialog-add-startup-cost="dialogAddNewVisible = false"
      @on-update-startup-cost-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Startup Cost -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddStartupCost from '@/views/Home/StoryMode/SetUp/StartupCosts/Dialogs/DialogAddStartupCost'
import DistributionOfStartupCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/DistributionOfStartupCosts'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import StartupCostsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/StartupCostsOverTime'
import StartupCostsTable from '@/views/Home/StoryMode/SetUp/StartupCosts/Tables/StartupCostsTable'
import { mapState } from 'vuex'

export default {
  name: 'ExpensesStartupCosts',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddStartupCost,
    DistributionOfStartupCosts,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    StartupCostsOverTime,
    StartupCostsTable
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false,
      tableRowsToExpand: [],
      tableAdjustment: {
        journalPage: false,
        period: 'monthly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      }
    }
  },

  computed: {
    ...mapState(['idea']),

    isStartupCostsEmpty () {
      return this.idea.storyMode.setUp.startupCosts.startupCosts.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.startupCostHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('startupCostFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
  },

  methods: {
    onShowAddNewDialog () {
      this.getStartupCosts()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getStartupCosts(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('startup-costs')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
